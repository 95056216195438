.modal {
    width: 100%;
    height: 100vh;
    display: flex;
    position: fixed;
    justify-content: center;
    z-index: 1051;
    // background-color: rgb(0, 0, 0, 0.5);
    top: 0;


    .outside {
        width: 100%;
        height: 100vh;
        position: absolute;
        z-index: 2;
        background-color: rgb(0, 0, 0, 0.8);
        top: 0;
    }

    .content {
        width: 500px;
        margin-top: 1.75rem;
        height: fit-content;
        background-color: white;
        border-radius: 0.5rem;
        padding: 1rem;
        position: relative;
        z-index: 2;
        max-height: 80vh;
        overflow: auto;

        @media (max-width: 576px) {
            width: 95%;
            padding: 12px;
        }


        div.headerContainer {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-bottom: 1rem;

            h1.title {
                font-size: 1.25rem;
                margin: 0;
                margin-right: auto;

                @media (max-width: 576px) {
                    font-size: 18px;
                    margin: 0 auto 0 10px;

                }
            }


            .cross {
                display: flex;
                align-items: center;
                cursor: pointer;
                
                svg {
                    font-size: 1.2rem;
                    color: #454F63;
                }
            }


        }

        hr {
            margin: 0;
            border: 0;
            border-bottom: 1px solid #dee2e6;
        }



        h1.header {
            margin: 1rem 0;
            font-size: 1.25rem;
            font-weight: 500;
            color: #32393f;
        }

        .buttonContainer {
            padding: 1rem 0 0;
            display: flex;
            flex-direction: row;
            justify-content: right;
            align-items: center;


            button {
                padding: 0.5rem 0.75rem;
                margin-left: 10px;
                text-align: center;
                border: none;
                color: white;
                font-size: 1rem;
                cursor: pointer;
                align-items: center;
                font-weight: 400;
                font-size: 1rem;
                position: relative;
                border-radius: 0.375rem;

                &.edit {
                    background-color: #6c757d;
                }

                &.delete {
                    background-color: #fd3550;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &.deleted {
                        span {
                            position: absolute;
                            right: 10px;
                            font-size: 15px;

                        }
                    }
                }



            }


        }
    }
}