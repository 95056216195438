.table {
    overflow: auto;
    border-radius: 5px;
    font-family: Roboto, sans-serif;
}

.table::-webkit-scrollbar {
    width: 0;
    height: 5px;
    display: block;
    background-color: rgb(214, 212, 212);

}

.table::-webkit-scrollbar-thumb {
    background-color: grey;
    border-radius: 6px;
    border: 3px solid grey;
}


.table .ant-table-container {
    border: none !important;
}

.table .ant-table {
    background: transparent;
}



.table thead tr th {
    background-color: white;
    border-right: 1px solid lightgrey !important;
    border: none;
    overflow: hidden;
    color: black;
    font-size: 14px;
    font-weight: 700;
    white-space: nowrap;
}

.table .row {
    color: black;
    margin: 2px 0;
    border-bottom: none;
    margin: 10px 0;
    border: none;
}

.table-row-light {
    background-color: rgba(0, 0, 0, 0.05);
}

.table-row-dark {
    background-color: white;
}


/* .table .row:hover {
    background-color: #f0eded;
} */

.table tbody tr td.ant-table-cell {
    border-top: 5px solid white;
    border-bottom: none;
    border-right: 1px solid lightgrey !important;
    padding: 0.7rem;
}

.table tbody tr td .ant-empty-description {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.table tbody tr td .ant-empty-description .icon {
    font-size: 40px;
    color: gray;

}

.table tbody tr td .ant-empty-description h1 {
    font-size: 18px;
    margin: 0;
    color: gray;

}


.ant-table-tbody>tr.ant-table-placeholder:hover>td {
    background-color: rgba(0, 0, 0, 0.05);

}

.table .row td {
    border-top: 5px solid #0A0A0A;
    border-bottom: none;
    background: transparent !important;
}

.table thead .row td:hover {
    background: transparent !important;
}


/* .pagination.ant-pagination {
    margin-bottom: 0;
} */

.pagination li {
    background-color: rgba(0, 0, 0, 0.05);
    color: #000;
    border: none;
}

.pagination li a {
    color: black;
}

.pagination li a:hover {
    color: black;
}

.pagination li.ant-pagination-prev button,
.pagination li.ant-pagination-next button {
    background-color: rgba(0, 0, 0, 0.05);
    border: none;
    color: black;
}

.pagination li.ant-pagination-item-active {
    background-color: #ecbf04;
    border-color: #ecbf04;
    color: white;

}

.pagination li.ant-pagination-item-active a {
    color: white;
}

.ant-pagination-options {
    display: none !important;
}