.cardContainer {
    width: 100%;
    padding: 20px 0;
    height: fit-content;

    .row {

        @media(max-width: 1199px) {
            justify-content: center;

        }

        .card {
            height: fit-content;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            border-radius: 10px;
            padding: 1rem;
            cursor: pointer;


            .infoContainer {
                display: flex;
                flex-direction: column;
            }

            .imageBack {
                display: inline-block;
                border-radius: 10px;
                justify-content: center;
                align-items: center;
                margin-left: auto;

                img {
                    width: 55px;
                    height: 55px;
                    filter: invert(1)
                }

            }

            p.title {
                margin: 0;
                color: white;
                font-size: 15px;

            }

            h4.count {
                color: white;
                margin: 0 0 0.25rem;
                font-size: calc(1.275rem + .3vw);
                font-weight: 500;
                line-height: 1.2;
            }

            h4.info {
                font-size: 13px;
                color: white;
                margin: 0;
            }


        }

        .chartContainer {
            background-color: white;
            width: 100%;
            border-radius: 10px;
            margin: 1.5rem 0;


            h6.title {
                padding: 0.5rem 1rem;
                margin-bottom: 0;
                color: #32393f;
                font-size: 1rem;
                font-weight: 500;
                line-height: 1.2;
                border-bottom: 1px solid rgba(0, 0, 0, 0.175);

            }

            .chart {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                padding: 0.5rem 1rem;
                height: 280px;

                .hidden {
                    display: none !important;
                }

            }
        }
    }
}