.tabsContainer {
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;

    .tabs {
        display: flex;
        flex-direction: row;
        position: relative;
        z-index: 1;

        &.right {
            justify-content: right;
        }

        &.left {
            justify-content: left;
        }

        .tab {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 8px 25px;
            cursor: pointer;
            user-select: none;
            border-radius: 10px 10px 0 0;



            &.active:hover,
            &.active {
                border: 1px solid #ecbf04;
                border-bottom: 1px solid white;

                p.label {
                    color: #ecbf04
                }

                .iconContainer {
                    span.icon {
                        display: none;
                    }

                    span.active_icon {
                        display: inline-flex;
                    }
                }
            }

            &:hover {
                border: 1px solid rgb(228, 226, 226);
            }

            .iconContainer {
                display: flex;
                flex-direction: row;

                span.icon,
                span.active_icon {
                    margin-right: 5px;
                    margin-top: 2px;
                }

                span.icon {
                    display: inline-flex;
                }

                span.active_icon {
                    display: none;
                }

            }


            p.label {
                margin: 0;
                color: #0d6efd;
                font-size: 16px;
            }
        }
    }


    hr.border {
        width: 100%;
        margin: 0;
        border: none;
        border-top: 1px solid #dee2e6;
        position: relative;
        bottom: 1px;
    }
}