.contentLayout {
    width: 100%;
    height: fit-content;
    display: flex;
    color: white;
    padding: 10px 0px 20px;
    flex-direction: column;

    .titleContainer {

        .headerContainer {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 12px 5px 1rem;

            h1.title {
                margin: 0;
                padding-right: 1rem;
                font-size: 20px;
                color: #4c5258;
                letter-spacing: .5px;
                font-weight: 400;
                font-family: Roboto, sans-serif;
                border-right: 1.5px solid #aaa4a4;

                @media (max-width:991px) {
                    display: none;

                }
            }


            // .verticalDivider {
            //     margin: 0 20px;
            //     height: inherit;
            //     border-left: 1px solid gray;
            // }

            .endpointContainer {
                padding-left: 1rem;
                display: flex;
                flex-direction: row;
                align-items: center;

                @media (max-width:991px) {
                    padding-left: 0;
                }

                .iconContainer {
                    display: flex;
                    align-items: center;
                    margin-bottom: 2px;
                    cursor: pointer;
                    width: 28px;
                    height: 28px;
                }

                span.next {
                    font-size: 16px;
                    display: flex;
                    align-items: center;
                    margin: 0 7px 0 4px;

                }

                .endpoint {
                    margin: 0;
                    font-size: 16px;
                    color: rgba(33, 37, 41, 0.75);
                    font-weight: 300;
                    font-family: Roboto, sans-serif
                }
            }

        }

        .divider {
            width: 100%;
            margin: 0 0 0.7rem;
            border: 0;
            border-top: 1px solid #dee2e6;
        }
    }


}