.game {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: right;

    .selectContainer {
        width: 100%;
        display: flex;
        flex-direction: row;
        margin: 0 0 20px 0;

        .select {
            width: 200px;

            &.left {
                margin: 0;
                margin-left: auto;
            }

            &.right {
                margin: 0;
                margin-left: 10px;
            }
        }
    }

    .infoContainer {
        width: 100%;
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;

        @media (max-width:1000px) {
            flex-direction: column;

            .pointContainer {
                margin: 0;
                margin-bottom: 30px;
            }

        }

        .pointContainer {
            margin-right: 50px;

            .inputContainer {
                width: 400px;
                display: flex;
                flex-direction: row;

                .input {
                    margin: 10px 10px 0 0;

                    .description {
                        margin: 0;
                        color: gray;
                    }
                }
            }

            .savePoint {
                padding: 5px 30px;
                color: white;
                background: #ecbf04;
                margin-top: 10px;
                border-radius: 10px;
                cursor: pointer;
                border: none;

            }
        }
    }


    div.addButtonContainer {
        display: flex;
        justify-content: right;
        margin: 10px 0;

        button.button {
            cursor: pointer;
            padding: 8px 25px;
            border-radius: 10px;
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 17px;
            background-color: #ecbf04;



            @media (max-width:768px) {
                padding: 10px 22px;
                font-size: 12px;
            }

            span {
                margin-right: 10px;
                font-size: 19px;
                display: flex;
                align-items: center;
                margin-top: 1px;

                @media (max-width:768px) {
                    margin-right: 6px;
                    font-size: 12px;
                }
            }
        }
    }

}

.modal {
    width: 500px;
    height: fit-content;
    background-color: white;
    border-radius: 20px;
    padding: 20px;
    position: relative;
    z-index: 2;
    max-height: 80vh;
    overflow: auto;

    @media (max-width: 576px) {
        width: 95%;
        padding: 12px;
    }

    div.headerContainer {
        display: flex;
        flex-direction: row;
        align-items: center;

        h1.title {
            font-size: 22px;
            margin: 0;
            margin-right: auto;

            @media (max-width: 576px) {
                font-size: 18px;
                margin: 0 auto 0 10px;

            }
        }

        span {
            cursor: pointer;
            font-size: 16px;
        }


    }

    hr.divider {
        margin: 15px 0 10px;
    }

    p.note {
        margin: 8px 15px 5px;
        color: gray;
        text-align: center;

        span {
            color: red;
            margin: 0;
            margin-right: 6px;
            font-weight: 700;

        }

    }

    div.fieldsContainer {
        padding: 20px;
        display: flex;
        flex-direction: column;

        .field {
            margin: 10px 0;

            p.addImageLabel {
                margin: 5px 0;
                color: grey;
            }

            div.imagesContainer {
                display: inline-block;
                width: 100%;

                div.image {
                    width: 100px;
                    height: 100px;
                    border-radius: 10px;
                    margin: 5px 10px;
                    display: inline-block;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

        }

        .container {
            display: flex;
            justify-content: center;



            div {
                width: fit-content;
                margin: 0;
                background-color: transparent;
                border-radius: 10px;
                overflow: hidden;

                span {
                    width: 150px;
                    display: flex;
                    flex-direction: column;

                    span.uploadIcon {
                        display: flex;
                        align-items: center;
                        font-size: 30px;
                        color: grey;
                        margin: 10px 0;
                    }
                }

                h3 {
                    color: grey;
                    opacity: 0.7;
                    font-size: 13px;
                }

            }
        }

        button.submit {
            width: fit-content;
            padding: 10px 50px;
            margin: 20px 0 5px auto;
            border-radius: 10px;
            background: #ecbf04;
            border: none;
            color: white;
            font-size: 17px;
            font-weight: 600;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

        }


    }
}