.contentManagement {
    overflow: auto;

    .tabComponentContainer {
        width: 100%;
        margin: 0;
        margin-bottom: 20px;
        display: flex;
        flex-direction: row;

        .button {
            cursor: pointer;
            padding: 8px 25px;
            margin-left: 20px;
            border-radius: 10px;
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 17px;
            background-color: #ecbf04;

            @media (max-width:768px) {
                padding: 10px 22px;
                font-size: 12px;
            }

        }
    }

    .editorContainer {
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: center;

        .editor {
            width: 100%;
            height: fit-content;
            color: black;
        }
    }
}