.fr-box.fr-basic .fr-element.fr-view {
    font-size: 17px;
    font-weight: 400;
    margin: 7px 0
}

.fr-box.fr-basic .fr-element.fr-view a {
    color: #ecf7c4;
}

.fr-box.fr-basic .fr-element.fr-view ol,
.fr-box.fr-basic .fr-element.fr-view ul {
    margin: 7px 0
}
