.main {
    height: 100vh;
    margin: 0;
    display: flex;
    flex-direction: row;
    background-color: white;

    @media (max-width:1200px) {
        .coverImageContainer {
            display: none !important;
        }

        .layout {
            width: 100vw !important;
        }
    }

    .coverImageContainer {
        width: calc(100vw - 33.33%);
        height: 100vh;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f7f7ff;

        .imageContainer {
            width: 70%;
            height: 70%;
            aspect-ratio: 16/9;

            img {
                width: 100%;
                height: 100%;
                transform: scaleX(-1);
            }
        }


    }


    .layout {
        min-height: 100vh;
        margin: 0;
        width: 33.33%;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: auto;

        @media (min-height:700px) {
            justify-content: center;
        }


        .content {
            height: inherit;
            width: 100%;
            margin: 30px 0;
            padding: 4rem;
            display: flex;
            flex-direction: column;
            align-items: center;

            &.small {
                padding: 4rem 5rem;

                img {
                    width: 150px;
                    height: 150px;
                }
            }


            img {
                position: relative;
                top: 0;
                width: 250px;
                height: 200px;
                aspect-ratio: 16/9;
                user-select: none;
            }

            .form {
                margin: 20px 0 0;
                width: 100%;
                color: white;
                display: flex;
                flex-direction: column;
                user-select: none;
                height: -webkit-fill-available;


                h2 {
                    margin: 0;
                    text-align: center;
                    font: normal normal normal Gilroy-SemiBold;
                    font-size: 1.7rem;
                    font-weight: 600;
                    color: black;
                }

                p {
                    text-align: center;
                    margin: 0;
                    margin-bottom: 5px;
                    color: gray;
                }

                p.label {
                    text-align: left;
                    margin: 0.7rem auto 0 0;
                    color: gray;
                }

                .fields {
                    height: inherit;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    margin: auto;
                    width: 100%;

                    @media(max-width : 576px) {
                        width: 100%;
                    }

                    .input {
                        margin: 12px;

                        button {
                            border-radius: 100%;
                            height: 30px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background-color: transparent;
                            cursor: pointer;
                            padding: 0;

                            svg {
                                font-size: 1.6rem;
                                color: white;
                                opacity: 0.6;
                            }
                        }



                    }

                    .checkboxField {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        margin: 10px 0 10px;

                        span {
                            color: black;

                        }

                        p.forgot {
                            margin: 0;
                            margin-left: auto;
                            color: #008cff;
                            cursor: pointer;
                            font-size: 14px;
                        }

                    }
                }

                .submit {
                    width: 100%;
                    height: 45px;
                    margin: 20px 0 0;
                    border-radius: 10px;
                    background-color: #ecbf04;
                    border-color: #ecbf04;
                    border: none;
                    color: white;
                    font-size: 1.3rem;
                    font-weight: 600;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

       
                }

            }

            .switch {
                width: 100%;
                height: 35px;
                text-align: center;
                position: relative;
                bottom: 0;
                color: #000;
                margin: 0;
                margin-top: 10px;
                font: normal normal normal Gilroy-Medium;
                font-size: 1.1rem;
                cursor: pointer;
                user-select: none;
                border-radius: 10px;
                border: none;
                display: flex;
                justify-content: center;
                align-items: center;

                &:hover {
                    background-color: #babbbc;
                }

                @media(max-width : 576px) {
                    font-size: 18px;
                }

            }
        }

    }
}