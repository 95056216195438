.leaderboard {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: right;

    .selectContainer {
        width: 100%;
        display: flex;
        flex-direction: row;
        margin: 0 0 20px 0;

        .select {
            width: 200px;

            &.left {
                margin: 0;
                margin-left: auto;
            }

            &.right {
                margin: 0;
                margin-left: 10px;
            }
        }
    }

    .toolContainer {
        display: flex;
        flex-direction: row;

        button.excel {
            padding: 0.375rem 0.75rem;
            color: #6c757d;
            border: 1px solid #6c757d;
            border-radius: 0.375rem;
            background-color: transparent;
            font-size: 18px;
            cursor: pointer;

            &:hover {
                color: white;
                background-color: #6c757d;
            }
        }

        p.recordCount {
            margin: 0;
            margin-left: auto;
            font-size: 16px;
            color: #6c757d;
        }
    }
}