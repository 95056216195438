.protectedLayout {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
    width: 100%;




    .background {
        display: none;

        @media (max-width:991px) {
            display: block;
            position: fixed;
            z-index: 9;
            width: 100%;
            height: 100vh;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.301);
        }
    }

    .menu {
        height: 100vh;
        display: flex;
        flex-direction: column;
        overflow: overlay;
        background-color: white;
        transition: 0.3s;
        position: relative;
        z-index: 1;
        box-shadow: 0px 0 20px -10px #888;

        @media (max-width:991px) {
            position: absolute;
            left: -300px;
            z-index: 10;
        }

        &.collapsed {
            width: 320px;

            @media (max-width:991px) {
                width: 280px;
            }

        }

        &.notCollapsed {
            width: 80px;
            align-items: center;
        }

        .drawer {
            display: none;
            text-align: center;
            cursor: pointer;
            background-color: transparent;
            font-size: 25px;
            margin-left: 5px;
            color: #ecbf04;

            @media (max-width:991px) {
                display: inline-flex;
            }
        }

        .logo {
            width: 100%;
            min-height: 150px;
            max-height: 150px;
            margin: 0 auto;
            text-align: center;
            padding: 5px 15px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            cursor: default;

            &.notCollapsed {
                padding: 0;
            }

            img {
                width: 100%;
                height: 100%;
                cursor: pointer;

                &.imageNotCollapsed {
                    max-width: 60px;
                    max-height: 60px;
                }

            }

        }

        hr.divider {
            width: 100%;
            margin: 5px 0 10px;
            border: 0;
            border-bottom: 1px solid #e4e4e4;

        }

        .itemContainer {
            padding: 0 10px;
            display: flex;
            flex-direction: column;
            height: 100%;

            .item {
                margin: 5px 0;
                padding: 10px 16px;
                text-decoration: none;
                display: flex;
                flex-direction: row;
                justify-content: left;
                align-items: center;
                font-weight: 500;
                font-size: 15px;
                font-family: Roboto, sans-serif;
                color: #5f5f5f;
                text-overflow: ellipsis;
                overflow: hidden;
                letter-spacing: .5px;
                border-radius: 0.25rem;
                transition: all .3s ease-out;

                svg {
                    font-size: 22px;
                }

                &:hover,
                &.active {
                    background-color: rgb(0 140 255 / 5%);
                    border-radius: 15px;
                    color: #ecbf04;
                }

                &.logout {
                    margin-top: auto;
                }

                p {
                    margin: 0 10px;
                    min-width: max-content;
                }
            }
        }

    }


    .routeContent {
        position: relative;
        width: 100%;
        background-color: #f7f7ff;
        display: flex;
        flex-direction: column;
        height: 100vh;
        overflow: hidden;

        .content {
            width: 100%;
            height: fit-content;
            display: flex;
            overflow: auto;
            padding: 0 30px;

        }

        .Navbar {
            padding: 0 1.5rem;
            display: flex;
            align-items: center;
            background-color: white;
            box-shadow: 0px 0px 46px -25px #888;

            @media (max-width:768px) {
                padding: 12px 10px;
            }

            .titleContainer {
                margin-right: auto;
                display: flex;
                align-items: center;
                justify-content: center;

                span.toggleButton {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    margin: 0 10px 0 0;
                    font-size: 20px;

                    @media (max-width:768px) {
                        font-size: 16px;
                    }

                }

                h3.title {
                    font-size: 22px;
                    font-weight: 700;
                    margin: 0;


                    @media (max-width:768px) {
                        font-size: 18px;
                    }

                }
            }

            .profileContainer {
                display: flex;
                flex-direction: row;
                padding: 0.5rem 1.3rem;
                border: 0;
                border-right: 1px solid #f0f0f0;
                border-left: 1px solid #f0f0f0;

                .image {
                    width: 42px;
                    height: 42px;

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 100%;
                    }
                }

                .userinfo {
                    margin-left: 0.7rem;
                    display: flex;
                    flex-direction: column;

                    p.name {
                        margin: 0;
                        display: inline-flex;
                        font-size: 15px;
                        font-weight: 500;
                        color: #413c3c;
                    }

                    p.type {
                        margin: 0;
                        display: inline-flex;
                        font-size: 13px;
                        color: #747474;
                    }
                }
            }

            .date {
                margin-right: auto;
                display: flex;
                font-size: 1.4rem;
                color: gray;
                justify-content: center;
                align-items: center;

                p {
                    margin: 0 20px;
                    font-size: 1rem;
                }
            }

            .search {
                display: flex;
                flex-direction: row;
                width: 20rem;
                height: auto;
                min-height: 30px;
                padding: 10px 20px;
                background-color: white;
                border-radius: 10px;
                justify-content: center;
                align-items: center;

                input {
                    width: 100%;
                    height: 100%;
                    background-color: transparent;
                    padding: 0 10px;
                    color: gray;
                    font-size: 16px;
                    border: none;
                    outline: none;
                }

                .searchButton {
                    width: 35px;
                    height: 30px;
                    border-radius: 100%;
                    background-image: linear-gradient(to right, #B52929, #ED1B1B);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: white;
                    cursor: pointer;
                    font-size: 1.3rem;
                }


            }

        }
    }



}