.searchContainer {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    margin-bottom: 5px;
    padding: 1rem 0;

    &.divider{
        border-top: 1px solid #dee2e6;
    }

    p.title {
        margin: 0;
        color: rgba(33, 37, 41);
        font-weight: normal;
        white-space: nowrap;
        text-align: left;
        font-size: 15px;
        font-family: Roboto, sans-serif;
    }

    .input {
        margin-left: 0.5em;
        display: inline-block;
        width: auto;
        min-height: calc(1.5em + 0.5rem + 2px);
        padding: 0.25rem rem 0.5rem;
        font-size: .875rem;
        border-radius: 0.25rem;
    }
}