.contentLayout {
    width: 100%;
    height: fit-content;
    display: flex;
    color: white;
    padding: 10px 0px 20px;
    flex-direction: column;

    .titleContainer {

        .headerContainer {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 12px 5px 1rem;

            h1.title {
                margin: 0;
                padding-right: 1rem;
                font-size: 20px;
                color: #4c5258;
                letter-spacing: .5px;
                font-weight: 400;
                font-family: Roboto, sans-serif;
                border-right: 1.5px solid #aaa4a4;

                @media (max-width:991px) {
                    display: none;
                }
            }

            // .verticalDivider {
            //     margin: 0 20px;
            //     height: inherit;
            //     border-left: 1px solid gray;
            // }

            .endpointContainer {
                padding-left: 1rem;
                display: flex;
                flex-direction: row;
                align-items: center;

                @media (max-width:991px) {
                    padding-left: 0;
                }

                .iconContainer {
                    display: flex;
                    align-items: center;
                    margin-bottom: 2px;
                    cursor: pointer;
                    width: 28px;
                    height: 28px;
                }

                span.next {
                    font-size: 16px;
                    display: flex;
                    align-items: center;
                    margin: 0 7px 0 4px;

                }

                .endpoint {
                    margin: 0;
                    font-size: 16px;
                    color: rgba(33, 37, 41, 0.75);
                    font-weight: 300;
                    font-family: Roboto, sans-serif
                }
            }

            div.addButtonContainer {
                display: flex;
                justify-content: right;
                margin: 0 0 0 auto;

                button.button {
                    cursor: pointer;
                    padding: 0.5rem 0.8rem;
                    border-radius: 0.375rem;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    font-size: 18px;
                    background: #ecbf04;

                    span {
                        display: inline-flex;
                        margin-left: 5px;
                    }

                }
            }

        }

        .divider {
            width: 100%;
            margin: 0 0 0.7rem;
            border: 0;
            border-top: 1px solid #dee2e6;
        }
    }


    .menu {
        height: inherit;
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        overflow-y: overlay;


        .contentTitle {
            margin-bottom: 40px;
            color: white;
        }

        .toolContainer {
            display: flex;
            flex-direction: row;

            button.excel {
                padding: 0.375rem 0.75rem;
                color: #6c757d;
                border: 1px solid #6c757d;
                border-radius: 0.375rem;
                background-color: transparent;
                font-size: 18px;
                cursor: pointer;

                &:hover {
                    color: white;
                    background-color: #6c757d;
                }
            }

            p.recordCount {
                margin: 0;
                margin-left: auto;
                font-size: 16px;
                color: #6c757d;
            }
        }
    }
}

.modal {
    width: 500px;
    margin-top: 1.75rem;
    height: fit-content;
    background-color: white;
    border-radius: 0.5rem;
    padding: 1rem;
    position: relative;
    z-index: 2;
    max-height: 80vh;
    overflow: auto;

    @media (max-width: 576px) {
        width: 95%;
        padding: 12px;
    }

    div.headerContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-bottom: 1rem;

        h1.title {
            font-size: 1.25rem;
            margin: 0;
            margin-right: auto;

            @media (max-width: 576px) {
                font-size: 18px;
                margin: 0 auto 0 10px;

            }
        }

        span {
            cursor: pointer;
            font-size: 16px;
        }


    }

    hr {
        margin: 0;
        border: 0;
        border-bottom: 1px solid #dee2e6;
    }

    div.fieldsContainer {
        padding: 20px;
        display: flex;
        flex-direction: column;

        .label {
            margin: 0.7rem 0 0;
        }

        .field {
            margin: 10px 0;

            p.addImageLabel {
                margin: 5px 0;
                color: grey;
            }

            div.imagesContainer {
                display: inline-block;
                width: 100%;

                div.image {
                    width: 100px;
                    height: 100px;
                    border-radius: 10px;
                    margin: 5px 10px;
                    display: inline-block;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

        }

        .container {
            display: flex;
            justify-content: center;



            div {
                width: fit-content;
                margin: 0;
                background-color: transparent;
                border-radius: 10px;
                overflow: hidden;

                span {
                    width: 150px;
                    display: flex;
                    flex-direction: column;

                    span.uploadIcon {
                        display: flex;
                        align-items: center;
                        font-size: 30px;
                        color: grey;
                        margin: 10px 0;
                    }
                }

                h3 {
                    color: grey;
                    opacity: 0.7;
                    font-size: 13px;
                }

            }
        }

        button.submit {
            width: fit-content;
            padding: 10px 50px;
            margin: 20px 0 5px auto;
            border-radius: 10px;
            background: #ecbf04;
            border: none;
            color: white;
            font-size: 17px;
            font-weight: 600;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

        }
    }
}