.changePassword {
    height: inherit;
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;

    @media (max-width:991px) {
        max-width: 700px;
        flex-direction: column;
        justify-content: center;

    }

    .imageContainer,
    .passwordContainer {
        padding: 10px;
        display: flex;
        flex-direction: column;
        background-color: white;
        border-radius: 10px;

    }

    .imageContainer {
        width: 30%;
        margin-right: 5px;
        justify-content: center;
        align-items: center;

        @media (max-width:991px) {
            width: 100%;
            margin: 0;
            margin-bottom: 10px;

        }

        img.userImage {
            width: 130px;
            height: 120px;
            border-radius: 50%;
            border: 4px solid #ecbf04;
        }

        h1.username {
            margin: 5px 0;
            font-size: 24px;
            font-weight: 500;
        }

        button.upload {
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: white;
            background-color: #ecbf04;
            border-radius: 0.375rem;
            cursor: pointer;
        }

    }

    .passwordContainer {
        width: 70%;
        padding: 15px 20px;
        margin-left: 5px;

        @media (max-width:991px) {
            width: 100%;
            margin: 0;
        }

        .row {
            margin: 10px 0;
            align-items: center;

            @media (max-width:991px) {
                margin: 15px 0;
            }

            p.title {
                margin: 0;
                color: black;
                font-size: 16px;
                font-weight: 500;

            }

            .submit {
                width: fit-content;
                padding: 0.5rem 1.5rem;
                line-height: 1.5;
                border-radius: 0.375rem;
                background: #ecbf04;
                border: none;
                color: white;
                font-size: 1rem;
                font-weight: 400;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
        }



    }


}